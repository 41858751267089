import { Input, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FailureIcon, InformationIcon, PasswordSuccessIcon, SuccessIcon } from "../../assets/icons";
import { calculateTimeAgo } from "./helper";
import "./monitoring-container.scss";
import { openNotificationWithIcon } from "../../utils";
import { Health_Status_Data } from "./constant";

const getColumns = () => [
  {
    title: "Status",
    key: "status",
    dataIndex: "current_status",
    className: "service-table-status",
    render: (text: any, record: any) => {
      if (record?.current_status === 1) return <SuccessIcon />;
      else return <FailureIcon />;
    },
  },
  {
    title: "Name",
    key: "name",
    className: "service-table-name",
    dataIndex: "service_name",
  },
  {
    title: "Description",
    key: "description",
    className: "service-table-description",
    dataIndex: "description",
  },
  {
    title: "Last Failed",
    key: "last_failed_at",
    className: "service-table-lastFailedAt",
    dataIndex: "last_failed_at",
    render: (text: any, record: any) => {
      return <p>{calculateTimeAgo(record.last_failed_at)}</p>;
    },
  },
  {
    title: "Last 25 results",
    key: "last_25_pings",
    className: "service-table-lastResults",
    dataIndex: "last_25_pings",
    render: (text: any, record: any) => {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 6px)",
            gap: "2px",
            justifyContent: "center",
          }}
        >
          {record.last_25_pings.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                background: item === 1 ? "#60B769" : "red",
                padding: "2px",
                width: "6px",
                height: "4px",
                borderRadius: "1px",
              }}
            ></div>
          ))}
        </div>
      );
    },
  },
  {
    title: (
      <Tooltip title="Based on last 24 hours">
        Availabiliy
        <InformationIcon />
      </Tooltip>
    ),
    key: "availability",
    className: "service-table-availability",
    dataIndex: "availability",
    render: (text: any, record: any) => {
      return (
        <div
          style={{
            background: "#D3D3D3",
            padding: "2px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {record.availability}
        </div>
      );
    },
  },
];

const oldGetColumns = () => [
  {
    title: "Status",
    key: "status",
    dataIndex: "statusCode",
    className: "service-table-statusNew",
    render: (text: any, record: any) => {
      if (record?.statusCode === 200) return <SuccessIcon />;
      else return <FailureIcon />;
    },
  },
  {
    title: "Name",
    key: "name",
    className: "service-table-nameNew",
    dataIndex: "serviceName",
  },
  {
    title: "Description",
    key: "description",
    className: "service-table-descriptionNew",
    dataIndex: "description",
  },
  {
    title: (
      <Tooltip title="Based on last 24 hours">
        Availabiliy
        <InformationIcon />
      </Tooltip>
    ),
    key: "availability",
    className: "service-table-availabilityNew",
    dataIndex: "message",
    render: (text: any, record: any) => {
      return (
        <div
          style={{
            background: "#D3D3D3",
            padding: "2px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            maxWidth: "60px",
          }}
        >
          {record.statusCode === 200 ? "100%" : "99%"}
        </div>
      );
    },
  },
];

interface MonitoringContainerProps {
  copilotName?: string;
}



export const MonitoringContainer = ({ copilotName }: MonitoringContainerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const [allServices, setAllServices] = useState<any>([]);

  

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const  data  = Health_Status_Data
        setAllServices(data);
        setServices(data);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setIsLoading(false);
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    let filteredServices = allServices;
    if (searchText.trim() !== "") {
      filteredServices = allServices.filter((record: any) =>
        record?.service_name?.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (copilotName === "CCA" || copilotName === "A&G Insights") {
      filteredServices = filteredServices.filter((record: any) => record.service_name !== "Wizard");
    }
    setServices(filteredServices);
  }, [searchText, allServices, copilotName]);

  return (
    <div style={{padding : "20px"}}>
      <div style={{ display: "flex" , alignItems:"center"}}>
        <h4 className="service-status-title" style={{ width: "150px" }}>
          Health Status
        </h4>
        {/* <div title="These services are monitored in our system" className="flex aic">
            <InformationIcon />
          </div> */}
        <div className="service-horizontal"></div>
        <Input
          className="record-search m-b"
          placeholder="Search for service"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "40em", marginBottom: 16 }}
        />
      </div>
      <Table
        className="service-table"
        columns={getColumns()}
        rowClassName={"cursor-pointer"}
        dataSource={services}
        pagination={false}
      />
    </div>
  );
};
