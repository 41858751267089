import React, { useEffect, useState } from "react";
import { Badge, Tooltip } from "antd";
import { FEEDBACK_STATUS } from "../../../../utils";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { documentService } from "../../../../api";
import { openNotificationWithIcon } from "../../../../utils";
import "./insights.scss";
import { SplitDataItem } from "../../constants";
import { useParams } from "react-router-dom";
import { commentService } from "../../../../api/comment";
import { CommentsModal } from "../../../modals";
import { PageComment } from "../../../../interfaces/document";

type Props = {
  selectedCategory: SplitDataItem;
  sectionId: number;
  getSections: () => Promise<void>;
};

export const Insights = ({
  selectedCategory,
  sectionId,
  getSections,
}: Props) => {
  const [noOfCommentsInsight, setNoOfCommentsInsight] = useState(
    selectedCategory.noOfCommentsInsight
  );
  const [feedbackStatus, setFeedbackStatus] = useState<any>({});
  const [sectionDetails, setSectionDetails] = useState({} as SplitDataItem);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { documentUuid } = useParams();
  const [comments, setComments] = useState([] as PageComment[]);

  useEffect(() => {
    const refactorFeedback: any = {};
    selectedCategory.insights?.forEach((element: any) => {
      const currKey = Object.keys(element).find((e) => e !== "Status")!;
      refactorFeedback[currKey] = element.Status;
    });
    setFeedbackStatus(refactorFeedback);
  }, [selectedCategory.insights, sectionId]);

  const formatKeyName = (key: string) => {
    switch (key) {
      case "buyer_name":
        return "Buyer Name";
      case "seller_name":
        return "Seller Name";
      case "property_address":
        return "Property Address";
      default:
        return key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
    }
  };

  const handleSubmitFeedback = async (status: string, key: string) => {
    try {
      if (status === feedbackStatus[key]) {
        await documentService.updateStatus(
          sectionId,
          FEEDBACK_STATUS.NOT_REVIEWED,
          key
        );
        setFeedbackStatus((prev: any) => ({
          ...prev,
          [key]: FEEDBACK_STATUS.NOT_REVIEWED,
        }));
        return openNotificationWithIcon("", "Feedback Reset", "info");
      }

      if (feedbackStatus[key] === FEEDBACK_STATUS.NOT_REVIEWED) {
        await documentService.updateStatus(sectionId, status, key);
        setFeedbackStatus((prev: any) => ({ ...prev, [key]: status }));
        return openNotificationWithIcon(
          "",
          status === FEEDBACK_STATUS.AGREE ? "Agreed" : "Disagreed",
          status === FEEDBACK_STATUS.AGREE ? "success" : "error"
        );
      }
    } catch (error: any) {
      console.error("Error updating feedback status", error);
      openNotificationWithIcon("", error.response.data.message, "error");
    }
  };

  const formatValue = (key: string, value: any) => {
    if (key === "property_address") {
      try {
        const address = JSON.parse(value);
        return !address?.address || address?.address === "null"
          ? "N/A"
          : address?.address;
      } catch (error) {
        console.error("Invalid address format", error);
        return !value || value === "null" ? "N/A" : value;
      }
    }
    return !value || value === "null" ? "N/A" : value;
  };

  const fetchCommentsByPage = async (record: SplitDataItem, type: string) => {
    setIsLoading(true);
    try {
      if (documentUuid) {
        const sectionWiseComments = await commentService.getAll({
          documentUuid,
          sectionId: record.sectionId,
          type,
        });
        setComments(sectionWiseComments);
        setNoOfCommentsInsight(sectionWiseComments.length);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOpen = async (record: SplitDataItem) => {
    setSectionDetails(record);
    setIsModalOpen(true);
    fetchCommentsByPage(record, "insight");
  };

  return (
    <div className="row jcsb gp-10">
      <div className="mapper">
        {selectedCategory.insights &&
          selectedCategory.insights.map((item: any, index: number) => (
            <div key={index}>
              {Object.keys(item).map(
                (key: string, index: number) =>
                  key !== "Status" && (
                    <div key={index} className="row jcsb gp-10">
                      <div>
                        <strong>{formatKeyName(key)}: </strong>{" "}
                        {formatValue(key, item[key])}
                      </div>
                     
                    </div>
                  )
              )}
              <br />
            </div>
          ))}
      </div>

      {isModalOpen && documentUuid && (
        <CommentsModal
          visibility={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          sectionDetails={sectionDetails}
          comments={comments}
          fetchComments={fetchCommentsByPage}
          documentUuid={documentUuid}
          isLoading={isLoading}
          type={"insight"}
          getSections={getSections}
        />
      )}
    </div>
  );
};
