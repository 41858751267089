import React, { createContext, useContext, useEffect, useState } from "react";
import { PageWrapper, DocumentContainer } from "../containers";
import { Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PAGE_URL, openNotificationWithIcon } from "../utils";
import { documentService } from "../api";
import { IOutletContext } from "../navigation/PrivateLayout";
import { DocumentData, DocumentReview } from "../interfaces/document";

export const DocumentReviewContext = createContext<DocumentReviewContextType>({
  documentReview: null as unknown as DocumentData,
  pdfData: { totalPages: 0 },
  setPdfData: () => {}
});

export const useDocumentReview = () => useContext(DocumentReviewContext);

export const Document = () => {
  const { documentUuid } = useParams();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ document, setDocument ] = useState<DocumentData>();
  const navigate = useNavigate();
  const { setPageHeader, resetPageHeader } = useOutletContext<IOutletContext>();
  const [ statistics, setStatistics ] = useState<any>({});
  const [ currentPoint, setCurrentPoint ] = useState<DocumentReview>();
  const [ pdfData, setPdfData ] = useState({ totalPages: 0 });

  if(!documentUuid) return <Navigate to={PAGE_URL.DOCUMENTS} replace/>

  useEffect(() => {
    const fetchSingleDocData = async (documentUuid: string) => {
      try {
        const documentData = await documentService.getDocumentByUuid(documentUuid);
        Object.keys(documentData).length ? setDocument(documentData) : navigate(PAGE_URL.DOCUMENTS);
        setPageHeader({
          pageRoute: `${PAGE_URL.DOCUMENT}/${documentData.documentUuid}`,
          pageTitle: documentData?.fileName!,
          displayBackButton: true,
        });
        console.log(5342342342,documentData);
      } catch (error: any) {
        console.error("Error displaying single document: ", error);
        openNotificationWithIcon("", error.response.data.message || error.message, "error");
        navigate(PAGE_URL.DOCUMENTS);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleDocData(documentUuid);
    return () => resetPageHeader();
  }, [documentUuid]);
  console.log(statistics);
  
  return <PageWrapper loading={isLoading} pageContainerChildren={
    <DocumentReviewContext.Provider value={{ documentReview: document!, setPdfData, pdfData }}>
      <DocumentContainer />
    </DocumentReviewContext.Provider>
  } />;
};

export default Document;

type StatisticsState = { [key: string]: { badge: number, noOfComments: number } };
type PdfState = { totalPages: number };

type DocumentReviewContextType = {
  documentReview: DocumentData;
  pdfData: PdfState,
  setPdfData: React.Dispatch<React.SetStateAction<{ totalPages: number }>>
};