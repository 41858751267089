import React from "react";
import { WarningFilled } from "@ant-design/icons";
import "./fall-back.scss";

export const NotAccessibleFallback = () => {
  return (
    <div className="tc">
      <WarningFilled className="warning-icon m-b" />
      <h3 className="m-b">Permission Denied</h3>
      <p>The resource you are trying to access have restricted access.</p>
      <p>Please refer to your System Admin</p>
    </div>
  );
};
