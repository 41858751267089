import React, { useState } from "react";
import { PageWrapper } from "../containers";
import { SummaryDataAndGraphs } from "../containers/summary-data";
import { MonitoringContainer } from "../containers/monitoring-container";




export const Status = () => {
  const [ isLoading, setIsLoading ] = useState(false);

  return <PageWrapper loading={isLoading} pageContainerChildren={
    <>
        <SummaryDataAndGraphs />
        <MonitoringContainer />
    </>
  } />;
};

export default Status;
