import { PageComment } from "../interfaces/document";
import { apiClient } from "./apiClient";

const getAll = async (params: { documentUuid: string; sectionId: number, type: string }): Promise<PageComment[]> => {
  const { data } = await apiClient.get("/comment", {
    params,
  });
  return data.data;
};

const save = async (body: { comment: string; username: string; sectionId: number; documentUuid: string, type: string }) => {
  const { data } = await apiClient.post("/comment", body);
  return data.data;
};

const update = async ({ id, ...body }: { comment: string; username: string; id: number }) => {
  const { data } = await apiClient.put(`/comment/update/${id}`, body);
  return data.data;
};

const deleteById = async ({ id, username }: { username: string; id: number }) => {
  const { data } = await apiClient.post(`/comment/delete/${id}`, { username });
  return data.data;
};

const savePageRange = async({id, pageStart, pageEnd}: {id: number, pageStart: number, pageEnd: number}) => {
  const { data } = await apiClient.put(`/section/document/${id}`, { pageStart, pageEnd });
  return data.data;
}

export const commentService = {
  deleteById,
  getAll,
  update,
  save,
  savePageRange
};
