import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { NotAccessibleFallback } from "../../../components";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;

interface APICallTrendProps {
  data: {
    tokenData: {
      date: string;
      token_count: {
        service_name: string;
        moduleName: string;
        token_count: number;
      }[];
    }[];
  };
}

const processAPIData = (data: APICallTrendProps["data"], granularity: string) => {
  if (!data.tokenData || data.tokenData.length === 0) {
    return [];
  }

  const result: Map<string, number> = new Map();

  data.tokenData.forEach(({ date, token_count }) => {
    let formattedDate: string;
    if (granularity === "weeks") {
      formattedDate = moment(date).format("YYYY-WW");
    } else if (granularity === "months") {
      formattedDate = moment(date).format("YYYY-MM");
    } else if (granularity === "years") {
      formattedDate = moment(date).format("YYYY");
    } else {
      formattedDate = moment(date).format("YYYY-MM-DD");
    }

    const totalTokenCount = token_count.reduce(
      (total, item) => total + Number(item.token_count),
      0
    );

    if (result.has(formattedDate)) {
      result.set(formattedDate, result.get(formattedDate)! + totalTokenCount);
    } else {
      result.set(formattedDate, totalTokenCount);
    }
  });

  // Convert Map to array of objects
  return Array.from(result, ([month, value]) => ({ month, value }));
};



export const APICallTrend = ({ data }: APICallTrendProps) => {
  const [loading, setLoading] = useState(false);
  const [tokenUsageData, setTokenUsageData] = useState([] as { month: string; value: number }[]);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [timeGranularity, setTimeGranularity] = useState("days"); // Default granularity

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const processedData = processAPIData(data, timeGranularity);
        setTokenUsageData(processedData);
      } catch (err: any) {
        if (err.response && err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon(
            "",
            err.response?.data?.message || "Error fetching data",
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data, timeGranularity]);

  const chartData = useMemo(() => {
    return {
      labels: tokenUsageData.map((item) => item.month),
      datasets: [
        {
          label: "No. of API Calls",
          data: tokenUsageData.map((item) => item.value),
          fill: false,
          borderColor: "#AE9BD5",
          pointBackgroundColor: "#AE9BD5",
          pointBorderColor: "#AE9BD5",
          tension: 0.4,
        },
      ],
    };
  }, [tokenUsageData]);

  const options = useMemo(() => {
    return {
      scales: {
        x: {
          type: "category", // Assuming time scale for x-axis
          stacked: true,
          grid: {
            drawOnChartArea: false
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    } as ChartOptions<"line">;
  }, []);

  const onTimeGranularityChange = (granularity: string) => {
    setTimeGranularity(granularity);
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  return (
    <div className="statistics">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4 className="summary-title">{`LLM calls Trend`}</h4>
          <p>Total number of LLM calls processed by days, week & months</p>
        </div>
        <div style={{ paddingBottom: "20px" }}>
          <Select
            defaultValue={timeGranularity}
            style={{ width: 100, height: 40 }}
            onChange={onTimeGranularityChange}
          >
            <Option value="days">Days</Option>
            <Option value="weeks">Weeks</Option>
            <Option value="months">Months</Option>
            <Option value="years">Years</Option>
          </Select>
        </div>
      </div>
      <div className="height">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};
