import React from "react";
import { PAGE_URL } from "../../../utils";
import { DataIcon, HomeIcon, ServiceStatusIcon, SettingsIcon } from "../../../assets/icons";

const USER_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  { link: PAGE_URL.DOCUMENTS, icon: <HomeIcon />, title: "Documents" },
  { link: PAGE_URL.STATUS, icon: <DataIcon />, title: "Status" },
];

const USER_LEFT_MENU_FOOTER_ITEMS: ILeftMenuItem[] = [
  // { link: PAGE_URL.SETTINGS, icon: <SettingsIcon />, title: "Settings" },
];

export const getAppMenuItems = (roleCode: string) => {
  return USER_LEFT_MENU_ITEMS;
};

export const getAppFooterMenuItems = (roleCode: string) => {
  return USER_LEFT_MENU_FOOTER_ITEMS;
}
