export const Health_Status_Data = [
    {
      current_status: 1,
      service_name: "Backend",
      description: "Backend service responsible for handling server-side logic.",
      last_failed_at: "2024-10-01T10:00:00Z",
      last_25_pings: [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      availability: "96%",
    },
    {
      current_status: 1,
      service_name: "Frontend",
      description: "Frontend service for handling client-side application logic.",
      last_failed_at: "2024-10-03T09:00:00Z",
      last_25_pings: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      availability: "99%",
    },
    {
      current_status: 0,
      service_name: "Webhook",
      description: "Webhook service for handling event-driven notifications.",
      last_failed_at: "2024-10-02T12:00:00Z",
      last_25_pings: [1, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1],
      availability: "88%",
    },
    {
      current_status: 1,
      service_name: "Auth",
      description: "Authentication service for managing user access and identity.",
      last_failed_at: "2024-10-04T08:30:00Z",
      last_25_pings: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
      availability: "98%",
    },
    {
      current_status: 0,
      service_name: "RBAC",
      description: "Role-Based Access Control service for managing permissions.",
      last_failed_at: "2024-10-03T11:15:00Z",
      last_25_pings: [1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 0, 1, 1, 0, 1],
      availability: "85%",
    },
    {
      current_status: 1,
      service_name: "PlaceHolder API",
      description: "Placeholder API for testing and integration purposes.",
      last_failed_at: "2024-10-05T07:00:00Z",
      last_25_pings: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1],
      availability: "99%",
    },
  ];
  